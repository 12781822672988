@import "../../mixins";

@import "variables";

@import "../general";
@import "../generic";

@import "../pwa";
@import "../backgrounds";
@import "../floatable";
@import "../forms";
@import "../tables";
@import "../tooltip";
@import "../badge";
@import "../ng-sidebar";
@import "../ngx-color";
@import "../ngx-editor";
@import "../tiptap";
@import "../typography";
@import "../transform";

@import "../animate";
@import "../ionic";
@import "../bootstrap";
@import "../swiper";

@import "app";

@import url('https://fonts.googleapis.com/css?family=#{$font-family-serif}:400,300,700');
