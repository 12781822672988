@import "variables";

color-sketch {
  .sketch-picker {
    box-shadow: none !important;
  }

  &.full-width {
    .sketch-picker {
      width: inherit !important;
      padding: 0;
    }

    .sketch-saturation {
      height: 100px;
      padding: inherit !important;
    }
  }
}
