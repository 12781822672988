@import "variables";

.badge {
  line-height: inherit;
  text-transform: inherit;
  font-size: map-get($font-sizes, "sm");
  font-weight: map-get($font-weights, "normal");
  border-radius: map-get($radii, "xs");
  padding: map-get($spacers, 1);
  min-width: 1.5rem;
  text-align: center;

  * {
    vertical-align: middle;
  }

  @each $key in map_keys($colors) {
    &.badge-#{$key} {
      border: 1px solid var(--ion-color-#{$key});
      background-color: var(--ion-color-#{$key});
      color: var(--ion-color-#{$key}-contrast);

      &.gradient {
        background-image: linear-gradient(45deg, var(--ion-color-#{$key}-darken) 0%, var(--ion-color-#{$key}) 100%);
        border: none;
        padding: 1px;
      }

      &.badge-border-thick {
        border: 2px solid var(--ion-color-#{$key});
      }

      &.badge-outline {
        background-color: transparent !important;
        color: var(--ion-color-#{$key}) !important;
      }

      &.badge-lg {
        font-size: map-get($font-sizes, "normal");
      }
    }
  }
}
