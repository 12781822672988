@import "variables";

//.absolute-page {
//  position: absolute;
//  left: map-get($spacers, 4);
//  right: map-get($spacers, 4);
//  height: $page-height-desktop !important;
//  overflow: hidden;
//
//  @include media-breakpoint-down(sm) {
//    left: 0;
//    height: $page-height-desktop !important;
//  }
//
//  &.scrollable {
//    overflow-y: scroll !important;
//  }
//}
//
//.full-height {
//  position: fixed;
//  top: $page-start;
//  left: 4rem;
//  bottom: 0;
//  right: 0;
//  height: $page-height-desktop !important;
//  width: auto !important;
//  padding: 0 map-get($spacers, 4);
//
//  @include media-breakpoint-down(sm) {
//    top: $page-start;
//    left: 0;
//    height: $page-height-mobile !important;
//    padding: map-get($spacers, 1) map-get($spacers, 1) 0 map-get($spacers, 1);
//  }
//}
//
//.usable-height {
//  height: $page-height-desktop !important;
//  @include media-breakpoint-down(sm) {
//    height: $page-height-mobile !important;
//  }
//}

.ktd-grid-item-placeholder {
  border: 1px dotted var(--ion-color-gray);
  background-color: var(--ion-color-light) !important;
}

.grid-item-resize-icon, .grabber {
  position: absolute;
  cursor: se-resize;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  color: inherit;

  &:after {
    content: "";
    position: absolute;
    right: 0px !important;
    bottom: 2px !important;;
    width: 5px;
    height: 5px;
    border-right: 4px double var(--ion-color-black) !important;
    border-bottom: 4px double var(--ion-color-black) !important;
  }
}
