@import "variables";

.ng-sidebar {
  width: $sidenav-width-open;
  //overflow: visible !important;

  &--animate {
    transition: background-color 200ms linear, opacity 200ms linear, transform .1s cubic-bezier(0, 0, 0.3, 1) !important;
    -webkit-transition: background-color 200ms linear, opacity 200ms linear, transform .1s cubic-bezier(0, 0, 0.3, 1) !important;
    -o-transition: background-color 200ms linear, opacity 200ms linear, transform .1s cubic-bezier(0, 0, 0.3, 1) !important;
  }
}
