@import "variables";
@import "bootstrap/scss/mixins/breakpoints";

.v-bottom {
  bottom: calc(4rem + #{$safe-area-inset-bottom} + #{map-get($spacers, 4)});
  @include media-breakpoint-up(md) {
    bottom: map-get($spacers, 4);
  }
}

.v-center {
  top: 50%;
  transform: translate(0, -50%);
}

.h-center {
  left: 50%;
  transform: translate(-50%, 0);
}

.trbl-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.left-xs {
  left: -1 * map-get($spacers, 1);
}

.top-left-0 {
  top: 0;
  left: 0;
}

.bottom-left-0 {
  bottom: 0;
  left: 0;
}

.top-right-0 {
  top: 0;
  right: 0;
}

.bottom-right-0 {
  bottom: 0;
  right: 0;
}

.horiz-right {
  top: 0;
  bottom: 0;
  right: 0;
}

.horiz-left {
  top: 0;
  bottom: 0;
  left: 0;
}

.centered-absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.overlay-absolute {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: $zindex-fixed - 1;
  background-attachment: fixed;
  background-size: cover;
}

.overlay-fixed {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: $zindex-fixed - 1;
  background-attachment: fixed;
  background-size: cover;
}

.background {
  z-index: -1 !important;
}
