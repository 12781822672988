@import "colors";

$safe-area-inset-top: env(safe-area-inset-top, 0px);
$safe-area-inset-bottom: env(safe-area-inset-bottom, 0px);
$safe-area-inset-right: env(safe-area-inset-right, 0px);
$safe-area-inset-left: env(safe-area-inset-left, 0px);

$gr: .618;
$spacer: .25rem;
$spacers: (
  0: 0,
  1: $spacer,
  2: $spacer * 2,
  3: $spacer * 3,
  4: $spacer * 4,
  5: $spacer * 5,
  6: $spacer * 6
);

$card-spacer-x: map-get($spacers, 4);
$card-spacer-y: map-get($spacers, 4);

$nav-bar-height: 3.3rem;
$sidenav-width-closed: 4rem;
$sidenav-width-open: 14rem;
$icon-bar-height: 4rem;

$page-start: $nav-bar-height + 13.8rem;
$page-height-desktop: calc(100vh - #{$page-start});
$page-height-mobile: calc(100vh - #{2 * $page-start} - 2.3 * #{$safe-area-inset-bottom});

$font-family-serif: "Nunito";
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;

$font-size-base: 1rem !default;
$font-sizes: (
  "xs": $font-size-base * .618,
  "sm": $font-size-base * .813,
  "normal": $font-size-base,
  "lg": $font-size-base * 1.25,
  "xl": $font-size-base * 1.618,
  "xxl": $font-size-base * 2.125,
) !default;

$header-sizes: (
  2: $font-size-base * 2.063,
  1: $font-size-base * 2.625,
);

$sizes: (
  "xxs": 1rem,
  "xs": 1.125rem,
  "sm": 1.5rem,
  "normal": 2rem,
  "md": 3rem,
  "lg": 4rem,
  "xl": 5rem,
  "xxl": 7rem
);

$font-weights: (
  "lighter": lighter,
  "light": 300,
  "normal": 400,
  "bold": 700,
  "bolder": bolder,
);

$radii: (
  "0": 0,
  "xs": .25rem,
  "sm": .5rem,
  "md": .75rem,
  "lg": 5rem,
  "xl": 50rem,
  "circle": 50%
);

$modals: (
  "xs": ("width": 225px, "height": 225px),
  "sm": ("width": 300px, "height": 300px),
  "md": ("width":500px, "height":500px),
  "lg": ("width":80vw, "height":80vh),
  "xl": ("width":95vw, "height":95vh)
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

$zindex-dropdown: 1000 !default;
$zindex-sticky: 1020 !default;
$zindex-fixed: 1030 !default;
$zindex-modal-backdrop: 1040 !default;
$zindex-modal: 1050 !default;
$zindex-popover: 1060 !default;
$zindex-tooltip: 1070 !default;

@import "bootstrap/scss/mixins/breakpoints";
@import "bootstrap/scss/mixins/grid";
@import "bootstrap/scss/mixins/grid-framework";

