@import "variables";

@each $key in map_keys($colors) {
  .bg-#{$key} {
    background-color: var(--ion-color-#{$key});
    color: var(--ion-color-#{$key}-contrast);

    &.bg-lighten {
      background-color: var(--ion-color-#{$key}-lighten);
      color: var(--ion-color-#{$key});
    }

    &.gradient {
      background-image: linear-gradient(45deg, var(--ion-color-#{$key}-darken) 0%, var(--ion-color-#{$key}) 100%);
    }
  }
}

.bg-base {
  background-image: url("../../assets/images/background.svg");
  color: $color-white !important;
  background-size: cover;

  ion-item {
    //--ion-color-base: transparent;
    //--ion-color-contrast: $color-white;
  }
}

