@import 'animate.css/source/_vars';
@import 'animate.css/source/_base';

/* Attention seekers  */
//@import '~animate.css/source/attention_seekers/bounce.css';
//@import '~animate.css/source/attention_seekers/flash.css';
@import 'animate.css/source/attention_seekers/pulse';
//@import '~animate.css/source/attention_seekers/rubberBand.css';
//@import '~animate.css/source/attention_seekers/shakeX.css';
//@import '~animate.css/source/attention_seekers/shakeY.css';
//@import '~animate.css/source/attention_seekers/headShake.css';
//@import '~animate.css/source/attention_seekers/swing';
//@import '~animate.css/source/attention_seekers/tada.css';
//@import '~animate.css/source/attention_seekers/wobble.css';
//@import '~animate.css/source/attention_seekers/jello.css';
//@import '~animate.css/source/attention_seekers/heartBeat.css';

/* Back entrances */
//@import '~animate.css/source/back_entrances/backInDown.css';
//@import '~animate.css/source/back_entrances/backInLeft.css';
//@import '~animate.css/source/back_entrances/backInRight.css';
//@import '~animate.css/source/back_entrances/backInUp.css';

/* Back exits */
//@import '~animate.css/source/back_exits/backOutDown.css';
//@import '~animate.css/source/back_exits/backOutLeft.css';
//@import '~animate.css/source/back_exits/backOutRight.css';
//@import '~animate.css/source/back_exits/backOutUp.css';

/* Bouncing entrances  */
//@import '~animate.css/source/bouncing_entrances/bounceIn.css';
//@import '~animate.css/source/bouncing_entrances/bounceInDown.css';
//@import '~animate.css/source/bouncing_entrances/bounceInLeft.css';
//@import '~animate.css/source/bouncing_entrances/bounceInRight.css';
//@import '~animate.css/source/bouncing_entrances/bounceInUp.css';

/* Bouncing exits  */
//@import '~animate.css/source/bouncing_exits/bounceOut.css';
//@import '~animate.css/source/bouncing_exits/bounceOutDown.css';
//@import '~animate.css/source/bouncing_exits/bounceOutLeft.css';
//@import '~animate.css/source/bouncing_exits/bounceOutRight.css';
//@import '~animate.css/source/bouncing_exits/bounceOutUp.css';

/* Fading entrances  */
@import 'animate.css/source/fading_entrances/fadeIn';
//@import '~animate.css/source/fading_entrances/fadeInDown';
//@import '~animate.css/source/fading_entrances/fadeInDownBig';
//@import '~animate.css/source/fading_entrances/fadeInLeft';
//@import '~animate.css/source/fading_entrances/fadeInLeftBig';
@import 'animate.css/source/fading_entrances/fadeInRight';
//@import '~animate.css/source/fading_entrances/fadeInRightBig';
//@import '~animate.css/source/fading_entrances/fadeInUp';
//@import '~animate.css/source/fading_entrances/fadeInUpBig';
//@import '~animate.css/source/fading_entrances/fadeInTopLeft';
//@import '~animate.css/source/fading_entrances/fadeInTopRight';
//@import '~animate.css/source/fading_entrances/fadeInBottomLeft';
//@import '~animate.css/source/fading_entrances/fadeInBottomRight';

/* Fading exits */
@import 'animate.css/source/fading_exits/fadeOut';
//@import '~animate.css/source/fading_exits/fadeOutDown';
//@import '~animate.css/source/fading_exits/fadeOutDownBig';
//@import '~animate.css/source/fading_exits/fadeOutLeft';
//@import '~animate.css/source/fading_exits/fadeOutLeftBig';
//@import '~animate.css/source/fading_exits/fadeOutRight';
//@import '~animate.css/source/fading_exits/fadeOutRightBig';
//@import '~animate.css/source/fading_exits/fadeOutUp';
//@import '~animate.css/source/fading_exits/fadeOutUpBig';
//@import '~animate.css/source/fading_exits/fadeOutTopLeft';
//@import '~animate.css/source/fading_exits/fadeOutTopRight';
//@import '~animate.css/source/fading_exits/fadeOutBottomRight';
//@import '~animate.css/source/fading_exits/fadeOutBottomLeft';

/* Flippers */
//@import '~animate.css/source/flippers/flip.css';
//@import '~animate.css/source/flippers/flipInX.css';
//@import '~animate.css/source/flippers/flipInY.css';
//@import '~animate.css/source/flippers/flipOutX.css';
//@import '~animate.css/source/flippers/flipOutY.css';

/* Lightspeed */
//@import '~animate.css/source/lightspeed/lightSpeedInRight.css';
//@import '~animate.css/source/lightspeed/lightSpeedInLeft.css';
//@import '~animate.css/source/lightspeed/lightSpeedOutRight.css';
//@import '~animate.css/source/lightspeed/lightSpeedOutLeft.css';

/* Rotating entrances */
//@import '~animate.css/source/rotating_entrances/rotateIn.css';
//@import '~animate.css/source/rotating_entrances/rotateInDownLeft.css';
//@import '~animate.css/source/rotating_entrances/rotateInDownRight.css';
//@import '~animate.css/source/rotating_entrances/rotateInUpLeft.css';
//@import '~animate.css/source/rotating_entrances/rotateInUpRight.css';

/* Rotating exits */
//@import '~animate.css/source/rotating_exits/rotateOut.css';
//@import '~animate.css/source/rotating_exits/rotateOutDownLeft.css';
//@import '~animate.css/source/rotating_exits/rotateOutDownRight.css';
//@import '~animate.css/source/rotating_exits/rotateOutUpLeft.css';
//@import '~animate.css/source/rotating_exits/rotateOutUpRight.css';

/* Specials */
//@import '~animate.css/source/specials/hinge.css';
//@import '~animate.css/source/specials/jackInTheBox.css';
//@import '~animate.css/source/specials/rollIn.css';
//@import '~animate.css/source/specials/rollOut.css';

/* Zooming entrances */
//@import '~animate.css/source/zooming_entrances/zoomIn.css';
//@import '~animate.css/source/zooming_entrances/zoomInDown.css';
//@import '~animate.css/source/zooming_entrances/zoomInLeft.css';
//@import '~animate.css/source/zooming_entrances/zoomInRight.css';
//@import '~animate.css/source/zooming_entrances/zoomInUp.css';

/* Zooming exits */
//@import '~animate.css/source/zooming_exits/zoomOut.css';
//@import '~animate.css/source/zooming_exits/zoomOutDown.css';
//@import '~animate.css/source/zooming_exits/zoomOutLeft.css';
//@import '~animate.css/source/zooming_exits/zoomOutRight.css';
//@import '~animate.css/source/zooming_exits/zoomOutUp.css';

/* Sliding entrances */
//@import '~animate.css/source/sliding_entrances/slideInDown.css';
//@import '~animate.css/source/sliding_entrances/slideInLeft.css';
//@import '~animate.css/source/sliding_entrances/slideInRight.css';
//@import '~animate.css/source/sliding_entrances/slideInUp.css';

/* Sliding exits */
//@import '~animate.css/source/sliding_exits/slideOutDown.css';
//@import '~animate.css/source/sliding_exits/slideOutLeft.css';
//@import '~animate.css/source/sliding_exits/slideOutRight.css';
//@import '~animate.css/source/sliding_exits/slideOutUp.css';
