@import "variables";

* {
  outline: none !important;
  -webkit-font-smoothing: antialiased;
  -webkit-appearance: none;
}

body {
  padding: $safe-area-inset-top $safe-area-inset-right $safe-area-inset-bottom $safe-area-inset-left;
  overflow: initial !important;
  position: initial !important;
  transform: none !important;
}

iframe {
  border: none !important;
}

.m-safe-inset-bottom {
  margin-bottom: $safe-area-inset-bottom;
}

.m-safe-inset-top {
  margin-top: $safe-area-inset-top;
}

[hidden] {
  display: none !important;
}
