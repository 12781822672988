@import "variables";

$displays: none, inline, inline-block, block, table, table-row, table-cell, flex, inline-flex;
$enable-grid-classes: true;
$grid-columns: 12;
$grid-gutter-width: 30px;
$grid-row-columns: 6;
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
);

$sizes: (
  100: 100%,
  auto: auto
);

@import "bootstrap/scss/utilities/flex";
@import "bootstrap/scss/utilities/spacing";
@import "bootstrap/scss/utilities/display";
@import "bootstrap/scss/utilities/sizing";
@import "bootstrap/scss/grid";

$positions: static, relative, absolute, fixed, sticky !default;
@import "bootstrap/scss/utilities/position";
