@import "variables";

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.text-wrap {
  white-space: pre-wrap;
}

.text-nowrap {
  white-space: nowrap;
}

.text-sentence::first-letter {
  text-transform: uppercase;
}

@each $key in [left, center, right] {
  .text-#{$key} {
    text-align: $key !important;
  }
}

@each $key in map_keys($font-sizes) {
  .font-size-#{$key} {
    font-size: map-get($font-sizes, $key) !important;
  }
}

@each $key in map_keys($font-weights) {
  .font-weight-#{$key} {
    font-weight: map-get($font-weights, $key) !important;
  }
}

@each $key in map_keys($header-sizes) {
  .h#{$key}, h#{$key} {
    font-size: map-get($header-sizes, $key) !important;
  }
}
