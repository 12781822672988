@import "variables";
@import "mixins";

.tool-tip {
  position: relative;

  .tool-tip-content {
    height: 0;
    min-width: 140px;
    padding: 0;
    color: var(--ion-color-white);
    font-size: map-get($font-sizes, sm);
    text-align: center;
    border-radius: map-get($radii, xs);
    overflow: hidden;

    position: absolute;
    z-index: 1;
    @include transition(all .05s ease-in-out);
    left: 20px;
    top: -5px;
  }

  &:hover {
    .tool-tip-content {
      height: auto;
      padding: map-get($spacers, 1);
    }
  }
}
