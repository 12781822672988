tiptap-editor {
  &.tiptap-full {
    .ProseMirror {
      font-family: Helvetica, sans-serif;
      font-size: 16px;

      &:not(:focus) p.is-editor-empty:first-child::before {
        content: attr(data-placeholder);
        float: left;
        color: #adb5bd;
        pointer-events: none;
        height: 0;
      }

      &.resize-cursor {
        cursor: col-resize;
      }

      .tableWrapper {
        padding: 0;
        overflow-x: auto;

        table {
          border-collapse: collapse;
          margin: 0;
          overflow: hidden;
          table-layout: auto;
          width: 100%;

          td, th {
            border: 1px dotted var(--ion-color-gray);
            box-sizing: border-box;
            padding: 5px;
            position: relative;
            vertical-align: top;

            > * {
              margin-bottom: 0;
            }
          }

          .selectedCell:after {
            background: rgba(200, 200, 255, 0.4);
            content: "";
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            pointer-events: none;
            position: absolute;
            z-index: 2;
          }

          .column-resize-handle {
            background-color: var(--ion-color-primary);
            bottom: -2px;
            position: absolute;
            right: -2px;
            pointer-events: none;
            top: 0;
            width: 4px;
          }

          p {
            margin: 0;
          }
        }
      }

      p {
        font-size: 10pt !important;
      }

      h1, &.h1 {
        font-size: 32pt !important;
        padding: 15pt 0;
      }

      h2, &.h2 {
        font-size: 24pt !important;
        padding: 12pt 0;
      }

      h3, &.h3 {
        font-size: 21pt !important;
        padding: 9pt 0;
      }

      h4, &.h4 {
        font-size: 16pt !important;
        padding: 6pt 0;
      }

      h5, &.h5 {
        font-size: 13pt !important;
        padding: 3pt 0;
      }

      h6, &.h6 {
        font-size: 12pt !important;
        padding: 1pt 0;
      }

      ul, ol {
        padding-inline-start: 1rem;
        margin-block-start: 0;
      }

      hr {
        margin: 2px 0;
        //height: calc(1pt * var(--tiptap-borderWidth, 1));
        //background-color: var(--tiptap-borderColor, #000);;
        border-bottom-style: var(--tiptap-borderStyle, solid);
        border-bottom-color: var(--tiptap-borderColor, #000);
        border-bottom-width: calc(1pt * var(--tiptap-borderWidth, 1));
      }

      table {
        &[borderLayout=no-borders] {
          border: none;
        }

        &[borderLayout=h-lines] {
          border: none;

          tr {
            border-bottom-style: var(--tiptap-borderStyle, solid);
            border-bottom-color: var(--tiptap-borderColor, #000);
            border-bottom-width: calc(1pt * var(--tiptap-borderWidth, 1));

            &:last-child {
              border-bottom: inherit;
            }
          }
        }

        &[borderLayout=wrap] {
          border-style: var(--tiptap-borderStyle, solid);
          border-color: var(--tiptap-borderColor, #000);
          border-width: calc(1pt * var(--tiptap-borderWidth, 1));
        }

        &[borderLayout=grid] {
          border: none;

          td {
            border-style: var(--tiptap-borderStyle, solid);
            border-color: var(--tiptap-borderColor, #000);
            border-width: calc(1pt * var(--tiptap-borderWidth, 1));
          }
        }
      }

    }
  }

  &.tiptap-minimal {
    .ProseMirror {
      font-family: inherit;
      font-size: 1rem !important;

      p {
        font-size: 1rem !important;
      }

      &:not(:focus) p.is-editor-empty:first-child::before {
        content: attr(data-placeholder);
        float: left;
        color: #adb5bd;
        pointer-events: none;
        height: 0;
      }
    }
  }
}
