@import "variables";

.NgxEditor {
  background: transparent !important;
  color: inherit !important;
  border: none !important;
  //overflow: auto !important;
  //height: 100%;
}

.NgxEditor__Content {
  padding: 0 !important;

  p {
    margin: 0 !important;
    font-size: inherit !important;
  }
}

.ProseMirror-selectednode {
  outline: 2px solid $color-gray !important;
}

.NgxEditor__Placeholder:before {
  cursor: default !important;
}

.NgxEditor__MenuBar {
  background-color: transparent !important;
  padding: 0 !important;
}

.NgxEditor__MenuItem {
  cursor: pointer !important;
}

.NgxEditor__Popup {
  position: absolute;
  top: -8px !important;
  overflow: auto;
  height: 48px;
}
