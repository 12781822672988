@import "variables";

/* Change autocomplete styles in WebKit */
input, textarea, select {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-size-adjust: map-get($font-sizes, "normal");
    transition: background-color 999999s ease-in-out 0s;
    background-color: transparent;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.blink {
  ion-label {
    animation-name: blinking;
    animation-duration: .5s;
    animation-iteration-count: infinite;
  }
}

@keyframes blinking {
  0% {
  }
  20% {
    color: $color-warning;
    font-weight: bold;
  }
  100% {
  }
}
