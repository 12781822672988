@import "variables";
@import "../mixins";

@each $key in map_keys($sizes) {
  .sqr-#{$key} {
    min-width: map-get($sizes, $key) !important;
    width: map-get($sizes, $key) !important;
    min-height: map-get($sizes, $key) !important;
    height: map-get($sizes, $key) !important;
  }

  .height-#{$key} {
    min-height: map-get($sizes, $key) !important;
    height: map-get($sizes, $key) !important;
  }

  .width-#{$key} {
    min-width: map-get($sizes, $key) !important;
    width: map-get($sizes, $key) !important;
  }
}

.height-nav-bar {
  min-height: $nav-bar-height;
  height: $nav-bar-height;
}

@each $key in map_keys($spacers) {
  .left-#{$key} {
    left: map-get($spacers, $key);
  }
  .right-#{$key} {
    right: map-get($spacers, $key);
  }
  .top-#{$key} {
    top: map-get($spacers, $key);
  }
  .bottom-#{$key} {
    bottom: map-get($spacers, $key);
  }
}

$i: pointer, default;
@each $val in $i {
  .cursor-#{$val} {
    cursor: $val !important;
  }
}

@each $key in map_keys($colors) {
  .color-#{$key} {
    color: var(--ion-color-#{$key}) !important;
  }
  .border-color-#{$key} {
    border-width: 1px;
    border-style: solid;
    border-color: var(--ion-color-#{$key});
  }
}

@each $key in map_keys($radii) {
  .radius-#{$key} {
    border-radius: map-get($radii, $key);
    overflow: hidden;
  }
}

@each $key in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($key) {
    $infix: breakpoint-infix($key, $grid-breakpoints);
    @for $i from 2 through 12 {
      .cols#{$infix}-#{$i} {
        @include row-nth($i);
      }
    }
  }
}

$i: hidden, visible, scroll, auto;
@each $val in $i {
  .overflow-#{$val} {
    overflow: $val !important;
  }
  .overflow-x-#{$val} {
    overflow-x: $val !important;
  }
  .overflow-y-#{$val} {
    overflow-y: $val !important;
  }
}

$i: 1, 2, 3;
@each $val in $i {
  .line-clamp-#{$val} {
    display: -webkit-box;
    -webkit-line-clamp: $val;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

@each $key in map_keys($shadows) {
  .shadow-#{$key} {
    box-shadow: #{map-get($shadows, $key)};
  }
}

@each $key in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($key) {
    $infix: breakpoint-infix($key, $grid-breakpoints);
    @each $shadowKey in map_keys($shadows) {
      .shadow#{$infix}-#{$shadowKey} {
        box-shadow: #{map-get($shadows, $shadowKey)};
      }
    }
  }
}

.border-0 {
  border: none;
}

.opacity-09 {
  opacity: .9;
}

.opacity-07 {
  opacity: .7;
}

.opacity-05 {
  opacity: .5;
}

.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 1;
}

@each $key in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($key) {
    $infix: breakpoint-infix($key, $grid-breakpoints);
    .opacity#{$infix}-05 {
      opacity: .5;
    }
  }
}

.debug {
  border: 1px solid red;
}

.code {
  font-family: monospace, monospace;
  white-space: pre-wrap;
}
