@import "variables";

.table {
  width: 100%;

  &.bottom-line {
    td, th {
      border-bottom: 1px solid var(--ion-color-light);
    }
  }

  td, th {
    vertical-align: middle !important;
    padding: map-get($spacers, 4);
  }
}

