$color-white: #fff;
$color-light: #f5f5f5;
$color-gray: #aaabb8;
$color-dark: #343a40;
$color-black: #000;
$color-facebook: #3B5997;
$color-google: #C73E2E;
$color-whatsapp: #25D366;
$color-viber: #665ca7;

$color-primary: #3377c8;
$color-primary-accent: rgb(79, 136, 213);
$color-primary-strong: #01316a;
$color-danger: #cb2c2c;
$color-warning: #ffaf30;
$color-success: #006A4D;
$color-info: #4d3dce;
$color-disabled: $color-gray;

$color-body: $color-dark;
$color-body-bg-desktop: $color-light;
$color-body-bg-mobile: $color-white;

$colors: (
  "primary": ("light": $color-primary, "dark": $color-primary),
  "primary-weak": ("light": lighten($color-primary, 30%), "dark": darken($color-primary, 30%)),
  "primary-strong": ("light": $color-primary-strong, "dark": $color-primary-strong),
  "danger": ("light": $color-danger, "dark": $color-danger),
  "warning": ("light": $color-warning, "dark": $color-warning),
  "success": ("light": $color-success, "dark": $color-success),
  "info": ("light": $color-info, "dark": $color-info),
  "gray": ("light": $color-gray, "dark": $color-gray),
  "white": ("light": $color-white, "dark": $color-dark),
  "white-weak": ("light": mix($color-warning, $color-white, 5%), "dark": mix($color-warning, $color-dark, 5%)),
  "light": ("light": $color-light, "dark": $color-black),
  "black": ("light": $color-black, "dark": $color-white),
  "dark": ("light": $color-dark, "dark": $color-white),
  "facebook": ("light": $color-facebook, "dark": $color-facebook),
  "google": ("light": $color-google, "dark": $color-google),
  "whatsapp": ("light": $color-whatsapp, "dark": $color-whatsapp),
  "viber": ("light": $color-viber, "dark": $color-viber),
);

//box-shadow: h-offset v-offset blur spread color |inset|initial|inherit;
$shadow-transparency: 1;
$shadow-offset: .5rem;
$shadow-blur: 5px;
$shadow-spread: -7px;
$shadows: (
  "none": none,
  "normal": 0 0 $shadow-offset * 3 -5px var(--ion-color-shadow),
  "tour": 0 0 0 99999px var(--ion-color-shadow),
  "sm": 0 0 $shadow-offset -3px var(--ion-color-shadow),
  "sm-top": 0 -1*$shadow-offset/2 $shadow-blur $shadow-spread var(--ion-color-shadow),
  "sm-bottom": 0 $shadow-offset/2 $shadow-blur $shadow-spread var(--ion-color-shadow),
  "sm-left": -1*$shadow-offset/2 0 $shadow-blur $shadow-spread var(--ion-color-shadow),
  "sm-right": $shadow-offset/2 0 $shadow-blur $shadow-spread var(--ion-color-shadow),
);
$amount-hover: 10%;
$amount-active: 5%;
$amount-transparency: 0.3;

@function get-active-color($c) {
  @if ($c == $color-white) {
    @return $c;
  }
  @return darken($c, $amount-active);
}

@function get-hover-color($c) {
  @if ($c == $color-white) {
    @return darken($c, $amount-hover / 8);
  }
  @if ($c == $color-light) {
    @return lighten($c, $amount-hover / 8);
  }
  @return lighten($c, $amount-hover);
}

@function get-trans-color($c) {
  @return transparentize($c, $amount-transparency);
}

@function get-contrast-color($c) {
  @if $c == $color-dark {
    @return $color-light;
  }
  @if $c == $color-light {
    @return $color-dark;
  }
  @if $c == $color-white {
    @return $color-black;
  }
  @if $c == $color-gray {
    @return $color-black;
  }
  @return $color-white;
}

@function get-rgb($c) {
  @return red($c), green($c), blue($c);
}
