@import "variables";

@mixin row-nth($cols) {
  @for $i from 1 through $cols - 1 {
    > *:nth-child(#{$cols}n+#{$i}) {
      padding-right: map-get($spacers, 2) !important;
    }
  }
}

@mixin blur($size) {
  -webkit-filter: blur($size);
  -moz-filter: blur($size);
  -o-filter: blur($size);
  -ms-filter: blur($size);
  filter: blur($size);
}

@mixin transition($value) {
  -webkit-transition: $value;
  -o-transition: $value;
  transition: $value;
}
