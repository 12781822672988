@import "@ionic/angular/css/normalize";
@import "@ionic/angular/css/structure";
@import "@ionic/angular/css/core";
@import "../mixins";
@import "variables";

:root {
  --ion-headings-font-weight: #{map-get($font-weights, "light")};
  --ion-button-radius: 14px;
  --ion-button-hover-opacity: .8;

  @each $key, $c in $colors {
    --ion-color-#{$key}: #{map-get($c, "light")};
    --ion-color-#{$key}-contrast: #{get-contrast-color(map-get($c, "light"))};
    --ion-color-#{$key}-shade: #{get-active-color(map-get($c, "light"))};
    --ion-color-#{$key}-tint: #{get-hover-color(map-get($c, "light"))};
    --ion-color-#{$key}-transparent: #{transparentize(map-get($c, "light"), .6)};
    --ion-color-#{$key}-lighten: #{lighten(map-get($c, "light"), 25%)};
    --ion-color-#{$key}-darken: #{darken(map-get($c, "light"), 10%)};
  }
  --ion-color-shadow: #{rgba(map-get(map-get($colors, "dark"), "light"), $shadow-transparency/3)};
  --ion-background-color: var(--ion-color-white);
  --ion-text-color: var(--ion-color-dark);
  --ion-text-color-rgb: var(--ion-color-black-rgb);
  --ion-card-color: var(--ion-color-dark);
  --ion-card-background: var(--ion-color-white);
  --ion-toolbar-background: var(--ion-color-white);
  --ion-overlay-background-color: var(--ion-color-white);
  $step: 5;
  @while $step < 100 {
    --ion-color-step-#{$step * 10}: #{darken($color-white, $step)};
    $step: $step + 5;
  }

  @include media-breakpoint-up(md) {
    --ion-font-family: "Nunito", "Helvetica Neue", "Roboto", sans-serif;
    --ion-background-color: var(--ion-color-light);
  }

  .theme-dark {
    @each $key, $c in $colors {
      --ion-color-#{$key}: #{map-get($c, "dark")} !important;
      --ion-color-#{$key}-contrast: #{get-contrast-color(map-get($c, "dark"))} !important;
      --ion-color-#{$key}-shade: #{get-active-color(map-get($c, "dark"))} !important;
      --ion-color-#{$key}-tint: #{get-hover-color(map-get($c, "dark"))} !important;
      --ion-color-#{$key}-transparent: #{transparentize(map-get($c, "dark"), .6)} !important;
      --ion-color-#{$key}-lighten: #{lighten(map-get($c, "dark"), 40%)} !important;
      --ion-color-#{$key}-darken: #{darken(map-get($c, "dark"), 10%)} !important;
    }
    --ion-color-shadow: #{rgba(map-get(map-get($colors, "light"), "dark"), $shadow-transparency*2)};
    --ion-background-color: var(--ion-color-white);
    --ion-text-color: var(--ion-color-dark);
    --ion-text-color-rgb: var(--ion-color-black-rgb);
    --ion-card-color: var(--ion-color-dark);
    --ion-card-background: var(--ion-color-white);
    --ion-toolbar-background: var(--ion-color-white);
    --ion-overlay-background-color: var(--ion-color-white);
    $step: 5;
    @while $step < 100 {
      --ion-color-step-#{$step * 10}: #{lighten($color-black, $step)};
      $step: $step + 5;
    }

    @include media-breakpoint-up(md) {
      --ion-background-color: var(--ion-color-light);
    }
  }
}

@each $key in map-keys($colors) {
  .ion-color-#{$key} {
    --ion-color-base: var(--ion-color-#{$key});
    --ion-color-base-rgb: var(--ion-color-#{$key}-rgb);
    --ion-color-contrast: var(--ion-color-#{$key}-contrast);
    --ion-color-contrast-rgb: var(--ion-color-#{$key}-contrast-rgb);
    --ion-color-shade: var(--ion-color-#{$key}-shade);
    --ion-color-tint: var(--ion-color-#{$key}-tint);
  }
}

.ion-color-twhite {
  --ion-color-base: transparent;
  --ion-color-contrast: var(--ion-color-white);
}

ion-item-options {
  border-bottom-width: 0 !important;
}

.textarea-label-placement-floating {
  .native-textarea {
    margin-top: 0 !important;
  }

  .textarea-wrapper {
    padding: 10px 5px;

    .textarea-wrapper-inner {
      height: 100%;
    }
  }
}

.input-label-placement-floating, .textarea-label-placement-floating {
  min-height: inherit !important;

  .input-wrapper, .textarea-wrapper {
    display: block;
    width: 100%;

    .label-text-wrapper {
      position: absolute;
      padding: 0 5px;
      margin-left: -5px;
      color: var(--ion-color-gray) !important;
      transform: translateY(0) scale(1) !important;
      -webkit-transform: translateY(0) scale(1) !important;
    }
  }

  &.has-focus, &.has-value {
    .input-wrapper, .textarea-wrapper {
      .label-text-wrapper {
        background-color: var(--ion-color-white);
        transform: translateY(-95%) scale(0.8) !important;
        -webkit-transform: translateY(-95%) scale(0.8) !important;
      }
    }
  }
}

ion-item {
  --border-color: var(--ion-color-gray);
  --background-focused-opacity: .03;
  font-size: $font-size-base;
  font-weight: map-get($font-weights, normal);

  ion-text {
    margin-top: 2px;
    margin-left: 2px;
  }

  &.error {
    --border-color: var(--ion-color-danger);

    &:after {
      content: "❗️";
      position: absolute;
      top: 4px;
      right: 3px;
      z-index: 1;
      font-size: map-get($font-sizes, "xs");
    }

    app-form-controls-errors, .ng-content-errors {
      display: block;
      position: absolute;
      left: 13px;
      right: 4px;
      //background-color: var(--ion-color-white);
      z-index: 2;
      overflow: hidden;
    }
  }

  &.warning {
    --border-color: var(--ion-color-warning);

    &:after {
      content: "⚠️";
      position: absolute;
      top: 4px;
      right: 3px;
      z-index: 1;
      font-size: map-get($font-sizes, "xs");
    }
  }

  &.selected {
    --color: var(--ion-color-primary);
  }

  &.ion-item-card {
    --ion-safe-area-left: 0;
    --padding-start: #{map-get($spacers, 2)};
    --padding-end: #{map-get($spacers, 2)};
    --inner-padding-end: 0;
    --min-height: 4.875rem;

    > ion-thumbnail {
      width: 3.5rem;
      padding-inline: 0 0;
      margin-inline-end: #{map-get($spacers, 2)};
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @include media-breakpoint-up(md) {
      --border-radius: #{map-get($radii, "xs")};
      --background: var(--ion-color-white);
      --border-width: 0px;
      --inner-border-width: 0;

      > ion-thumbnail {
        width: 4.563rem;
        padding-inline: #{map-get($spacers, 2)} #{map-get($spacers, 2)};
        border-right: 1px solid var(--ion-color-gray-transparent);
      }
    }
  }

  &.summary {
    width: 100%;
    padding: 0;
    margin: 0;
    --inner-border-width: 0;
    --background: transparent;
  }

  &.input, &[appIonFloatingLabel], &.floating-label {
    --ion-safe-area-left: 0;
    --background: var(--ion-color-white);
    --border-radius: #{map-get($radii, "xs")};
    --border-width: 1px;
    --padding-start: #{map-get($spacers, 3)};
    --inner-padding-start: #{map-get($spacers, 3)};
    --padding-end: #{map-get($spacers, 3)};
    --inner-padding-end: 0;
    --inner-border-width: 0;

    > ion-icon, > ion-input {
      margin-inline: 0;

      &[type="number"] {
        text-align: right;
      }
    }

    > ion-checkbox {
      margin-inline: 0 #{map-get($spacers, 2)};
    }

    > select {
      background-color: transparent;
    }
  }

  &.item-interactive-disabled {
    > ion-label {
      --color: var(--ion-color-gray) !important;
      opacity: 1 !important;
    }

    &.keep-label-color {
      > ion-label {
        --color: initial !important;
        opacity: 1 !important;
      }
    }
  }

  &[appIonFloatingLabel], &.floating-label {
    overflow: visible;
    position: relative;

    > ion-label {
      --color: var(--ion-color-gray) !important;
      @include transition(.1s ease-in-out all);
      position: absolute;
      pointer-events: none;
      left: #{map-get($spacers, 3)};
      top: 3px;
      min-width: initial;
      max-width: initial;
      width: initial;
    }

    > ion-label.floating, &:focus-within > ion-label {
      z-index: 2;
      font-size: 80%;
      left: #{map-get($spacers, 2)};
      top: -1.1rem;
      padding: 0 5px;
      background-color: var(--ion-color-white);
    }
  }

  &.no-border {
    --border-width: 0;
    --inner-border-width: 0;
  }

  &.no-hover {
    --background-hover-opacity: 0;
  }

  &.no-padding {
    --padding-start: 0;
    --padding-end: 0;
    --inner-padding-start: 0;
    --inner-padding-end: 0;
  }

  &.no-padding-end {
    --padding-end: 0;
    --inner-padding-end: 0;
  }

  &.no-margin {
    margin: 0 !important;
  }

  &.bg-transparent {
    --background: transparent;
  }

  &.h-100 {
    &::part(native) {
      height: 100%;
    }
  }
}

ion-input {
  --placeholder-color: var(--ion-color-gray) !important;

  &.input-disabled {
    opacity: 1 !important;

    .native-wrapper {
      opacity: .3;
    }
  }

  &.text-uppercase {
    .native-wrapper {
      text-transform: uppercase;
    }
  }
}

ion-textarea {
  --placeholder-color: var(--ion-color-gray) !important;
}

ion-item-sliding {
  @include media-breakpoint-up(md) {
    box-shadow: #{map-get($shadows, "sm")};
  }
}

ion-header {
  ion-toolbar {
    --border-width: 0;

    &:last-of-type, &:first-of-type {
      --border-width: 0 !important;
    }
  }

  &::after {
    height: 0 !important;
    background-image: none !important;
  }
}

ion-toolbar {
  --ion-safe-area-left: 0;
  --ion-safe-area-right: 0;

  &.no-border {
    --border-width: 0 !important;
    box-shadow: none !important;
  }
}

ion-thumbnail {
  display: flex;
  align-items: center;

  //&.round {
  //  --border-radius: #{map-get($radii, "circle")};
  //
  //  app-auth-img {
  //    border-radius: #{map-get($radii, "circle")};
  //    width: 100%;
  //    height: 100%;
  //    object-fit: cover;
  //    overflow: hidden;
  //  }
  //}
}

ion-card {
  ion-card-content {
    padding: $card-spacer-y $card-spacer-x !important;
    line-height: 1.2 !important;
    font-size: $font-size-base !important;
  }

  &.full-page {
    z-index: 0;
    margin: 0;
    border-radius: 0;
    box-shadow: none;

    ion-card-content {
      padding: $card-spacer-y 0 !important;
    }

    @include media-breakpoint-up(md) {
      ion-card-content {
        padding: 0 !important;
      }
      padding: $card-spacer-y $card-spacer-x !important;
      border-radius: #{map-get($radii, "md")};
      box-shadow: #{map-get($shadows, "sm")};
      margin: #{map-get($spacers, 2)};
    }
  }
}

ion-checkbox {
  --background: var(--ion-color-light);
  //margin-inline: 0 8px;
  //min-width: 26px;
  //width: 26px;
  //min-height: 26px;
  //height: 26px;
  //--size: 26px;
  //--checkbox-background-checked: var(--ion-color-primary);

  &::part(container) {
    border-radius: #{map-get($radii, "xs")};
    border: 1px solid var(--ion-color-primary);
  }
}

ion-button {
  --border-radius: var(--ion-button-radius);
  border-radius: var(--ion-button-radius);
  min-height: #{map-get($sizes, "md")} !important;

  &:hover {
    opacity: var(--ion-button-hover-opacity);
  }

  &.button-solid {
    min-width: 200px;

    ion-icon {
      min-width: #{map-get($sizes, "md") * 0.5};
      width: #{map-get($sizes, "md") * 0.5};
      min-height: #{map-get($sizes, "md") * 0.5};
      height: #{map-get($sizes, "md") * 0.5};
    }
  }

  &.width-auto {
    min-width: auto;
  }
}

ion-button, ion-fab-button, ion-item {
  margin: 0;
  letter-spacing: 0;
  font-size: $font-size-base;

  @each $key in map_keys($sizes) {
    &.sqr-#{$key}, &.height-#{$key} {
      ion-icon, app-auth-img {
        min-width: #{map-get($sizes, $key) * 0.5};
        width: #{map-get($sizes, $key) * 0.5};
        min-height: #{map-get($sizes, $key) * 0.5};
        height: #{map-get($sizes, $key) * 0.5};
      }
    }
  }
}

ion-label {
  &.wrap {
    white-space: normal !important;
  }
}

ion-popover {
  @include media-breakpoint-up(md) {
    --background: var(--ion-color-white) !important;
    --box-shadow: #{map-get($shadows, "normal")} !important;
    --min-width: #{map-get(map-get($modals, "sm"), "width")} !important;
    --width: auto !important;
    --max-height: 50%;
    .popover-viewport {
      overflow-y: auto;
      overflow-x: hidden;
    }
    &.tour {
      --box-shadow: #{map-get($shadows, "tour")} !important;

      ion-card-title {
        font-size: map-get($font-sizes, "xl");
        font-weight: map-get($font-weights, "normal");
      }

      ion-card-content {
        white-space: pre-line;
        padding-top: 0 !important;
      }
    }
    &.action-bar {
      --offset-y: 1rem;
    }
    &.at-bottom {
      &::part(content) {
        transform: translateY(-100%);
      }
    }
  }
}

ion-fab-button {
  &.no-shadow {
    --box-shadow: none;
  }
}

ion-title {
  @include media-breakpoint-up(md) {
    padding-left: 0;
  }
}

body:not(.theme-dark) {
  ion-toolbar {
    ion-segment {
      --ion-toolbar-segment-background-checked: var(--ion-color-gray);

      ion-segment-button {
        --indicator-height: 0;

        ion-icon, ion-label {
          color: var(--ion-color-black);
        }
      }
    }
  }
}

ion-toast {
  position: fixed;
  --white-space: pre;
}

ion-segment {
  &.segment-tabs {
    --background: transparent;

    ion-segment-button {
      --background: transparent;
      --border-radius: 0;
      --border-style: none;
      --indicator-color: var(--ion-color-primary);
      --indicator-height: 2px;
      --indicator-transform: translateY(42px);
    }
  }
}

ion-datetime {
  &::part(calendar-day) {
    font-size: $font-size-base;
  }
}

.searchbar-input.sc-ion-searchbar-ios {
  font-size: $font-size-base;
}

.action-sheet-cancel::after {
  opacity: 0 !important;
}

.responsive-modal {
  padding: calc(#{$card-spacer-y/2} - 2px + #{$safe-area-inset-top}) 0 0 !important;
  --border-radius: #{map-get($radii, "md")};
  --background: transparent;
  position: fixed;

  &::part(content) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    @include media-breakpoint-up(md) {
      border-bottom-right-radius: #{map-get($radii, "md")};
      border-bottom-left-radius: #{map-get($radii, "md")};
      padding: 0 !important;
      --box-shadow: #{map-get($shadows, "sm")};
    }
  }

  @include media-breakpoint-up(md) {
    .ion-page {
      position: initial;
      display: initial;
    }
    @each $key in map_keys($modals) {
      &.#{$key} {
        --width: #{map-get(map-get($modals, $key), "width")};
        --max-width: #{map-get(map-get($modals, $key), "width")};
        --height: #{map-get(map-get($modals, $key), "height")};
        --max-height: #{map-get(map-get($modals, $key), "height")};
      }
      &.w-#{$key} {
        --width: #{map-get(map-get($modals, $key), "width")};
        --max-width: #{map-get(map-get($modals, $key), "width")};
      }
      &.h-#{$key} {
        --height: #{map-get(map-get($modals, $key), "height")};
        --max-height: #{map-get(map-get($modals, $key), "height")};
      }
    }
  }
}

form, .form {
  &.condensed {
    ion-item, app-dynamic-field, app-dynamic-field-edit, .form-item {
      margin-bottom: calc(2px + map-get($spacers, 2));
    }
  }

  ion-item, app-dynamic-field, app-dynamic-field-edit, .form-item {
    display: block;
    margin-bottom: map-get($spacers, 4);

    > .description {
      margin-top: - map-get($spacers, 3);
      color: var(--ion-color-gray);
      font-size: map-get($font-sizes, sm);
      white-space: break-spaces;
    }
  }

  > *:last-child {
    //margin-bottom: 0;
  }
}

.ion-card-subtitle {
  // !!! don't use <ion-card-subtitle> component - will result in shitty page flickers on ios rubber effect
  color: var(--ion-color-dark);
  margin: 0 0 map-get($spacers, 4);
  padding: 0;
  font-weight: map-get($font-weights, "bold");
  letter-spacing: .4px;
  text-transform: uppercase;
}

.ion-card-title {
  --color: var(--ion-text-color);
  margin: 0 0 map-get($spacers, 4);
  padding: 0;
  font-size: map-get($font-sizes, "xl");
  font-weight: map-get($font-weights, "bold");
  word-break: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.item-disabled {
  opacity: 1;

  ngx-editor {
    opacity: .4;
  }
}

html {
  font-family: var(--ion-font-family);
}

a {
  background-color: transparent;
  color: var(--ion-color-primary);
  text-decoration: none;
}

.app-item-card {
  &.selected {
    .ion-item-card {
      --background: var(--ion-color-primary-weak) !important;
    }
  }

  &.disabled {
    .ion-item-card {
      --background: var(--ion-color-gray-transparent);
      opacity: .7;
    }
  }
}

.nav-bar-height {
  height: $nav-bar-height;

  &.big-height {
    height: $nav-bar-height + 1rem;
  }
}

.cdk-virtual-scroll-content-wrapper {
  width: 100%;
}
